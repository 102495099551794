@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

h1
  max-width: 700px
  width: 100%
  margin: 0 auto

.top-section.smaller
  +media('md')
    padding-bottom: 0

#bear-plans
  background-color: $bg-regular
  text-align: center
  padding-bottom: 70px
  position: relative

  &.free-instead
    // hack: hide the floating edge for russia desktop view
    .item-free:before
      display: none

    // hack: fix the right-hand shadow on desktop
    .item-column.item-free
      .inner
        padding-top: 40px

        +media('min-md')
          margin-left: 15px
          margin-right: 15px
          padding-right: 0

      .content
        padding-top: 5px
        max-width: 70%

        h2
          font-size: 1.4em

          +media('md')
            display: block  // hack override

    .not-available-container
      margin: 0
      max-width: 994px !important

      .item-column
        display: flex
        flex-direction: row
        justify-content: space-evenly

        .bear-pic
          height: 330px

          +media('md')
            height: 230px
            margin-top: 50px


          .bear-img-wrap
            height: 100%
            width: 100%

            img
              height: 100%
              width: 100%

        .content
          max-width: 40%
          margin: 0
          align-items: center

          +media('md')
            margin-top: 15px
            max-width: 50%

          h2
            margin-bottom: 0

            +media('md')
              margin-bottom: 30px

          .not-available-txt
            margin-top: -40px

            &.india
              margin-top: 0

            +media('md')
              margin-top: 0px

          .read-more-btn
            margin: 0 auto

            +media('md')
              margin-top: 30px


  +media('md')
    padding-top: 40px
    padding-bottom: 40px

  .plans
    display: flex
    margin: 0 auto
    max-width: 1000px

  .bear-pic
    position: relative
    height: 100px
    z-index: 9

    +media('md')
      height: auto

    h2
      position: relative
      z-index: 1
      bottom: -220px

    .bear-img-wrap
      width: 100%
      position: relative

      img
        margin-left: auto
        margin-right: auto

      &.free
        margin-top: 130px
        position: absolute

        +media('md')
          position: relative
          margin-top: 0

        img
          max-width: 70px
          margin-right: -15px
          &.small
            display: none

          +media('md')
            margin: 0 auto
            max-width: 210px
            &.large
              display: none

            &.small
              display: block

      &.unlimited
        margin-top: -100px
        position: absolute

        +media('md')
          position: relative
          margin-top: 0

        img
          display: block
          max-width: 390px
          margin-left: -15px

          &.fall-sale
            height: 335px

          +media('md')
            margin-left: auto
            max-width: 250px

      &.stop-censorship
        position: absolute
        bottom: -15px

        +media('md')
          box-shadow: 0 -10px 10px -15px #888 inset

        img
          display: block
          max-width: 287px
          margin-top: 10px
          margin: 0 auto

          +media('md')
            max-width: 240px

      &.teams
        margin-top: -40px
        position: absolute

        +media('md')
          position: relative
          margin-top: 20px

        img
          max-width: 250px

      img
        width: 100%
        margin: 0 auto

  .item-column
    flex: 1 1 0
    position: relative
    z-index: 3
    position: relative
    display: flex
    flex-direction: column

    .inner-wrap
      display: flex
      width: 100%
      flex-grow: 1
      overflow: hidden
      z-index 20

      +media('md')
        overflow: visible

    &.item-free
      &:before
        content: ''
        position: absolute
        right: 0
        bottom: 55px
        width: 1px
        background: $grey
        height: 71%

        +media('md')
          display: none

      +media('md')
        .bear-pic
          height: 150px
          margin-top: -40px

      .inner
        +media('md')
          padding-top: 0

        +media('min-md')
          margin-left: 15px
          margin-right: -15px
          padding-right: 15px

    &.item-unlimited
      h2.spring-sale:after
        align-items: center
        background: #7C807F
        border-radius: 100%
        box-sizing: border-box
        color: #fff
        content: "67% OFF"
        display: flex
        font-size: 1.15rem
        font-style: italic
        font-weight: 900
        height: 3.6em
        line-height: 1
        padding: 0.1em 0 0 0 // adjust centering
        position: absolute
        right: 0.6em
        text-align: center
        top: -3.5em
        transform: rotate(12deg)
        width: 3.6em

      .inner
        position: relative
        z-index: 1 // hack? ensure that 'All-day security...' is shown

        +media('md')
          margin-top: -20px
          position: relative
          z-index: 5

        +media('min-md')
          margin-right: 15px
          margin-left: -15px
          padding-left: 15px

      +media('md')
        .pricing
          position: relative

          &.seasonal:after
            align-items: center
            background: #A3401D
            border-radius: 100%
            box-sizing: border-box
            color: #fff
            content: "58% OFF"
            display: flex
            font-size: 1.15rem
            font-style: italic
            font-weight: 900
            height: 3.6em
            line-height: 1
            padding: 0.1em 0 0 0 // adjust centering
            position: absolute
            right: -0.5em
            text-align: center
            top: -2.5em
            transform: rotate(12deg)
            width: 3.6em

    &.item-teams
      margin-left: 20px

      .inner-wrap
        overflow: visible

    .inner
      padding-top: 130px
      display: flex
      width: 100%
      flex: 1 1 0
      flex-direction: column
      align-items: center
      justify-content: flex-start
      padding-bottom: 40px
      margin-right: 0
      border-radius: 20px
      margin-top: 15px
      margin-bottom: 15px
      box-shadow: -1px 3px 20px rgba(0, 0, 0, 0.12)

      +media('md')
        box-shadow: none
        padding-top: 0

    .content
      padding-top: 30px
      max-width: 260px
      margin: auto
      display: flex
      flex-direction: column
      flex-wrap: nowrap
      flex-grow: 1
      justify-content: space-evenly

      .check-list
        flex: 1

      p:not(.fine-print), ul, li
        font-size: 15px

      +media('md')
        padding-top: 0

        & > p // sale hack
          order: 3

    .savings
      position: absolute
      right: -10px
      top: 20px
      display: flex
      padding: 20px
      justify-content: center
      font-weight: bold
      font-size: 20px
      align-items: center
      width: 70px
      height: 70px
      border-radius: 50%
      text-transform: uppercase
      background-color: #B42025
      color: $white
      z-index: 5
      transform: rotate(25deg)

  h2
    margin-bottom: 20px
    font-weight: 700

    +media('md')
      display: none

  p.copy
    margin-bottom: 15px

  .copy
    order: 2

  ul.check-list
    text-align: left
    margin: 10px auto 20px

    li
      margin-bottom: 10px
      width: 260px
      max-width: 100%

    +media('md')
      order: 3

  .pricing
    order: 3
    margin: 15px 0 10px

    +media('md')
      order: 2
      margin-top: 0px

    .amount
      font-size: 1.8em
      font-weight: 600

      +media('md')
        font-size: 1.6em

  .btn
    width: 100%
    max-width: 250px
    display: block
    align-self: flex-end
    margin: auto auto 0
    order: 4

    +media('md')
      max-width: 200px

  .payment-info
    padding: 50px 15px

    img.payment-img
      width: 100%
      max-width: 253px
      display: block
      margin: 10px auto

    &.free-instead
      margin: 0 auto
      max-width: 50%
      padding-top: 0

      +media('md')
        max-width: 100%

  .wirecutter
    margin: 0 auto 100px

  .faq-section
    h2
      margin-bottom: 40px

    .faqs
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between

      .faq
        width: 45%
        text-align: left
        margin-bottom: 40px

        +media('sm')
          width: 100%

    ul
      margin-left: 0
      padding-left: 20px

/* ---------- Mobile Selections ---------- */
#bear-images, #bear-plans
  .select-free
    +media('md')
      .item-teams
        display: none

      .item-unlimited
        display: none

      p.copy
        margin-top: 15px

  .select-teams
    +media('md')
      .item-free
        display: none

      .item-unlimited
        display: none

  .select-unlimited
    +media('md')
      .item-free
        display: none

      .item-teams
        display: none

.mobile-menu
  width: 95%
  max-width: 360px
  margin: 15px auto 10px
  border-radius: 30px
  overflow: hidden
  border: 1px solid $mid-dark-grey
  display: none

  +media('md')
    display: flex

  button
    width: 33.3333%
    padding: 10px
    border-radius: 0
    border: none
    background-color: transparent
    color: $mid-dark-grey
    border-right: 1px solid $mid-dark-grey
    outline: 0

    &:focus
      outline: 0

    &:last-of-type:not(.selected)
      border-right: none

    &.selected
      background-color: $mid-dark-grey
      color: $white



dump_media_cache()
